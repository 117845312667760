// File: src/components/Header.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <NavLink to="/" className="logo">
        RotoBalance
      </NavLink>
      <nav className="nav">
        <NavLink to="/" activeClassName="active" exact>
          Home
        </NavLink>
        <NavLink to="/yahoo-auth" activeClassName="active">
          RotoEdge
        </NavLink>        
        <NavLink to="/contact" activeClassName="active">
          Contact
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
