// File: src/components/Contact.js
import React from 'react';
import './Contact.css';
import TwitterLogo from '../assets/twitter-logo.png'; // Import the Twitter logo PNG

const Contact = () => {
  const twitterHandle = 'rotobalance';
  const twitterUrl = `https://twitter.com/${twitterHandle}`;
  const emailAddress = 'your.email@example.com'; // Replace with your actual email address

  return (
    <section className="contact">
      <h2>Contact Us</h2>
      <p>Email: <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
      <a href={twitterUrl} className="twitter-btn" target="_blank" rel="noopener noreferrer">
        <img src={TwitterLogo} alt="Twitter" style={{ width: '24px', height: '24px' }} /> Follow us on X
      </a>
    </section>
  );
};

export default Contact;
