// File: src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Contact from './components/Contact'; // Make sure to create this component
import Footer from './components/Footer';
import YahooAuth from './components/YahooAuth';
import YahooCallback from './components/YahooCallback';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/contact" element={<Contact />} /> {/* New Contact route */}
          <Route path="/yahoo-auth" element={<YahooAuth />} /> {/* Hidden route */}
          <Route path="/yahoo-callback" element={<YahooCallback />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
