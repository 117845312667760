import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './YahooCallback.css';

const YahooCallback = () => {
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // debugMessages variable below is for debugging
    // uncomment lines using it when necessary
    // const [debugInfo, setDebugInfo] = useState([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        const storedCodeVerifier = sessionStorage.getItem('codeVerifier');
        const storedEmail = sessionStorage.getItem('email');
        sessionStorage.removeItem('codeVerifier'); // Clean up after retrieving
        sessionStorage.removeItem('email');

        if (code && storedCodeVerifier && storedEmail) {
            // const debugMessages = [];
            // debugMessages.push(`Code received: ${code}`);
            // debugMessages.push(`Code Verifier from session: ${storedCodeVerifier}`);
            // debugMessages.push(`Email : ${storedEmail}`);

            // Intermediate Perl script endpoint
            const perlScriptUrl = 'https://www.rotobalance.com/cgi-bin/millsap.pl';

            // Prepare the body of the POST request
            const formData = new FormData();
            formData.append('code', code);
            formData.append('code_verifier', storedCodeVerifier);
            formData.append('email', storedEmail);

            // Use fetch to make the POST request to the Perl script
            fetch(perlScriptUrl, {
                method: 'POST',
                body: formData, // Sending as form data
            })
            .then(response => {
                // debugMessages.push(`Response Status: ${response.status}`);
                return response.json();
            })
            .then(data => {
                // debugMessages.push(`Response Data: ${JSON.stringify(data)}`);
                // setDebugInfo(debugMessages); // Step 2: Update debug state

                if (data.result) {
                    setSuccessMessage(`Successfully connected to your Yahoo league. There will be a RotoEdge email in your inbox soon. Thanks for signing up! `);
                } else if (data.error) {
                    throw new Error(data.error);
                }
            })
            .catch((error) => {
                // debugMessages.push(`Error: ${error.toString()}`);
                // setDebugInfo(debugMessages); // Update debug state with error
                setErrorMessage(`An error occurred while exchanging the token: ${error.toString()}`);
            });
        }
    }, [location]);

    return (
        <div className="container">
            {errorMessage && (
                <div className="error-message">
                    <span className="error-icon">✕</span>
                    <p>Error: {errorMessage} </p>
                    <a href="https://www.rotobalance.com/yahoo-auth" style={{ color: '#FF0000', textDecoration: 'underline' }}>
                        Try again
                    </a>
                </div>
            )}
            {successMessage && (
                <div className="success-message">
                    <p>Success: {successMessage} </p>
                    <a href="https://www.rotobalance.com/" style={{ color: '#ADD8E6', textDecoration: 'underline' }}>
                        Home
                    </a>
                </div> 
            )}
            {/* 
            Step 3: Display debug information
            {debugInfo.map((info, index) => (
                <div key={index}><p>Debug Info: {info}</p></div>
            ))}
            */}
        </div>
    );
};

export default YahooCallback;
