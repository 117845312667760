import './YahooAuth.css';
import React, { useState, useEffect } from 'react';
import config from '../config.json';

const YahooAuth = () => {
    const [consumerKey, setConsumerKey] = useState('');
    const [codeVerifier, setCodeVerifier] = useState('');
    const [codeChallenge, setCodeChallenge] = useState('');
    const [nonce, setNonce] = useState('');
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);

    const emailRegex = /\S+@\S+\.\S+/;

    useEffect(() => {
        const generatedCodeVerifier = generateCodeVerifier();
        const generatedNonce = generateNonce();
        setConsumerKey(config.CONSUMER_KEY);
        setCodeVerifier(generatedCodeVerifier);
        generateCodeChallenge(generatedCodeVerifier).then(setCodeChallenge);
        setNonce(generatedNonce);
    }, []);

    useEffect(() => {
        setIsEmailValid(emailRegex.test(email));
    }, [email]);

    const generateNonce = () => {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);
        return arrayToString(array).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    };

    const generateCodeVerifier = () => {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);
        return arrayToString(array).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    };

    const arrayToString = (buffer) => {
        return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
    };

    const generateCodeChallenge = async (codeVerifier) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = await window.crypto.subtle.digest('SHA-256', data);
        return arrayToString(digest).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
    };

    const handleLogin = () => {
        if (!codeChallenge || !nonce || !isEmailValid) {
            console.error('PKCE code challenge, nonce, or email is not ready or invalid.');
            return;
        }

        sessionStorage.setItem('codeVerifier', codeVerifier);
        sessionStorage.setItem('email', email);

        const callbackUri = encodeURIComponent('https://www.rotobalance.com/yahoo-callback');
        const authUrl = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${consumerKey}&redirect_uri=${callbackUri}&response_type=code&scope=${encodeURIComponent('fspt-r')}&code_challenge=${codeChallenge}&code_challenge_method=S256&nonce=${nonce}`;

        window.location.href = authUrl;
    };

    // Styling for marketing text, label, button, and container
    const marketingTextStyle = { textAlign: 'center', marginBottom: '40px', margin: 'auto' };
    const labelStyle = { display: 'block', margin: '10px 0', color: '#000' };
    const buttonStyle = isEmailValid ? { cursor: 'pointer' } : { backgroundColor: '#ccc', cursor: 'not-allowed', color: '#fff' };
    const containerStyle = { marginBottom: '10px' };

    const spacerStyle = {
        height: '20px',
    };

    return (
        <div className="container">
            <h1>Welcome to RotoEdge</h1>
            <div style={spacerStyle}></div> {}
            <p style={marketingTextStyle}>
                Your daily secret weapon in Roto fantasy basketball. Dive into a world where each morning greets you with crucial league updates, NBA insights, and player analytics tailored to give you the upper hand.
            </p>
            <div style={spacerStyle}></div> {}
            <p style={marketingTextStyle}>
                With RotoEdge, you're not just playing the game; you're outsmarting the competition. From sleeper picks to strategic moves, our data-driven guidance is your fast track to the top of your league.
            </p>
            <div style={spacerStyle}></div> {}
            <p style={marketingTextStyle}>
                Ready to transform your fantasy basketball experience? To get started for free, simply enter your preferred email and authenticate with Yahoo below. With RotoEdge, victory isn't just a possibility; it's within reach.
            </p>
            <div style={spacerStyle}></div> {}
            <div style={spacerStyle}></div> {}
            <div style={containerStyle}>
                <label htmlFor="userEmail" style={labelStyle}></label>
                <input
                    type="email"
                    id="userEmail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your preferred email"
                    style={{ marginBottom: '10px' }}
                />
            </div>
            <button onClick={handleLogin} disabled={!isEmailValid} style={buttonStyle}>
                Log in with Yahoo
            </button>
        </div>
    );
};

export default YahooAuth;
