// File: src/components/Home.js
import React from 'react';
import logo from '../assets/RotoBalance.png'; // Make sure to place the logo in the src/assets/ folder
import './Home.css';

const Home = () => {
  return (
    <main className="home">
      <img src={logo} alt="RotoBalance Logo" className="logo" />
      <h2 className="tagline">Unlock the Secrets of Fantasy Basketball</h2>
      <p className="marketing-text">
        Based on an extensive analysis of over 30,000 fantasy leagues from the 2016-2023 seasons, our app reveals the critical link between league size and the stats needed to win each category.
      </p>
      <p className="marketing-text">
        We've pinpointed the exact stats required to stay competitive, tailored to your league's size. As basketball evolves, our app adapts, helping you navigate the shifting landscape of player roles and game strategies.
      </p>
      <p className="marketing-text">
        Now free for all users, our app is your guide to drafting a powerhouse team. Build a well-rounded squad and dominate your fantasy basketball league.
      </p>
      <p className="call-to-action">
        Download now on <a href="https://play.google.com/store/apps">Google Play</a> or the <a href="https://www.apple.com/app-store/">Apple Store</a> and transform your draft strategy today!
      </p>
    </main>
  );
};

export default Home;
